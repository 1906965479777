import React from "react";
import { Handle, Position } from "reactflow";

const Media = ({ data }) => {
  return (
    <div
      style={{
        padding: "10px",
        border: "1px solid #ddd",
        borderRadius: "4px",
        background: "#fff",
      }}
    >
      <div>{data.label}</div>
      <Handle
        type="target"
        position={Position.Left}
        id="target1"
        style={{ top: '50%',  background: "#333333",
            width: "12px", 
            height: "12px",  
            borderRadius: "50%", }}
      />
      {data.buttonList &&
        data.buttonList.map((_, index) => (
          <Handle
            key={`source${index}`}
            type="source"
            position={Position.Right}
            id={`source${index}`}
            style={{ top : `calc(130px + ${index * 25}px)`,  background: "#333333",
            width: "12px", 
            height: "12px",  
            borderRadius: "50%",}}
          />
        ))}
    </div>
  );
};

export default Media;
